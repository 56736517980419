export const environment = {
    production: false,
    e2e: false,
    firebase: {
        apiKey: "AIzaSyDK6wWtSqA61FWKaWTvPBhLfGdicQ1ETTs",
        authDomain: "cwl-5138eb79cdaf-qa.firebaseapp.com"
    },
    brain_base_url: "https://bcp.api-test.deloitte.camp",
    authParams: ["iframe"],
    signInType: 'signInWithPopup',
    aoh_url: "https://aoh-qa.bcp.deloitte.camp",
    store_url: "https://store-qa.bcp.deloitte.camp",
    api_base_url: "https://bcp.api-test.deloitte.camp",
    asset_redirect_uri: {},
    upload: {
        allowUnclassifiedFilesUpload: true,
        confidentialMetadata: [],
        existingMetadata: []
    },
    hasEnterpriseAssetSearch: false,
    showBarrierQuestions: false,
    disableAOName: false,
    onboarding: {
        displayVideo: false,
        video_url: "https://storage.googleapis.com/dcsp-catalog-assets-/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4",
        development: {
            frontend_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf",
            backend_url: "https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse",
            business_support_email: "mailto:GlobalDeloitteAi@deloitte.com?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
        }
    },
    barrierQuestionsAbort: {
        mailAddress: "Thorsten.Johanntoberens@Bertelsmann.de"
    },
    aohQuestions: {
        hide_onboardingWelcomePopup: true,
        business_hideDelegationRadio: true,
        business_hideServiceOfferings: true,
        business_hideIndustries: true,
        business_hideFY2: true,
        business_hideFY3: true,
        business_hideGeographyQuestion: true,
        technical_hideContainerizedQuestion: true,
        technical_hideInformOnboardingTeamButton: true,
        hideBeforeYouStartPopup: true,
        hideAohPreviewButton: true
    },
    support: {
        ccEmail: "",
        contact: "mailto:GlobalDeloitteAi@deloitte.com?subject=Question%20regarding%20Deloitte%20AI%20Marketplace"
    },
    onboarding_options: {
        "gen_ai": {
            api_url: "https://bcp-geniusai.connect-test.api.deloitte.ai",
            app_url: "https://geniusai-test.bcp.deloitte.camp/workflow-selector"
        },
        "manual": {},
        "api_passthrough": {},
        "soft_onboarding": {}
    }
};
